<script setup lang="ts">
interface Props {
  name: string
  path: string
  icon: string
  beta?: boolean
  bottom?: boolean
  exact?: boolean
}

const props = defineProps<Props>()
const route = useRoute()

const isActive = computed((): boolean => {
  if (props.exact) {
    return route.path === props.path;
  }

  return route.path.includes(props.path);
})
</script>

<template>
  <VHover>
    <template #default="{ props: hoverProps, isHovering }">
      <li
        v-bind="hoverProps"
        :class="{ bottom }"
      >
        <RouterLink 
          :to="path" 
          :style="isActive ? 'background-color: rgb(136,135,142,0.4)' : isHovering ? 'background-color: #fff;' : 'background-color: #281222;'"
        >
          <VBtn 
            size="36" 
            variant="text"
            :color=" isActive ? 'rgb(136,135,142,0)' : isHovering ? '#fff' : '#281222'"
            class="nav-button-item"
          >
            <VIcon 
              :icon="icon" 
              :color="(isHovering && !isActive) ? '#281222' : '#f2f2f2'"
              size="20"
            />
          </VBtn>
          <span class="links_name">
            {{ name }}          
            <AppChipBeta
              v-if="beta"
              :dark="isActive || (!isHovering && !isActive)"
            />
          </span>
        </RouterLink>
        <span class="tooltip">{{ name }}</span>
      </li>
    </template>
  </VHover>
</template>

<style scoped>
.bottom {
  position: relative;
  margin-top: auto;
  margin-bottom: 150px;
}
</style>
