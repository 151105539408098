<script lang="ts" setup>
import VueApexCharts from "vue3-apexcharts";
import { getLineChartConfig, LineChartOptions } from '@/utils/chartHelpers';
import type { AppChartLineData } from '@/types/app-component.types';
import { formatCurrency } from "@/@core/utils/formatters";

interface Props {
  labels: string[]
  series: AppChartLineData[]
  currency?: string;
  title?: string
  width?: number
  height?: number
  percent?: boolean
  yAxisMax?: number
}

const props = defineProps<Props>()

const chartConfig = computed(() => {
  const options: LineChartOptions = {};

  if (props.currency) {
    options.tooltipFormatter = (value: any) => {
      return formatCurrency(value, props.currency);
    }

    options.yAxisFormatter = (value: any) => {
      return formatCurrency(value, props.currency);
    }
  }

  if (props.percent) {
    options.tooltipFormatter = (value: any) => {
      return `${value}%`;
    }

    options.yAxisFormatter = (value: number) => {
      return parseInt(`${value}`, 10) + '%';
    }
  }

  options.yAxisMax = props.yAxisMax;

  return getLineChartConfig(props.labels, props.title, options);
})
</script>

<template>
  <VueApexCharts
    :width="width"
    :height="height"
    type="area"
    :options="chartConfig"
    :series="series"
  />
</template>
