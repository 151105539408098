<script lang="ts" setup>
import type { Component } from 'vue'
import { injectionKeyIsVerticalNavHovered } from '@layouts'
import type { VerticalNavItems } from '@layouts/types'
import VerticalNavItem from './VerticalNavItem.vue'
import { useOrganizationStore } from '@/stores/useOrganizationStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useAgentStore } from '@/stores/useAgentStore'
import { useLayoutStore } from '@/stores/useLayoutStore';
import { useFeaturesStore } from '@/stores/useFeaturesStore'
import { useErrorStore } from '@/stores/useErrorStore'

interface Props {
  tag?: string | Component
  navItems: VerticalNavItems
  isOverlayNavActive: boolean
  toggleIsOverlayNavActive: (value: boolean) => void
}

withDefaults(defineProps<Props>(), {
  tag: 'aside',
})

const refNav = ref()

const layoutStore = useLayoutStore();
const featuresStore = useFeaturesStore();
const errorStore = useErrorStore();

const { features } = storeToRefs(featuresStore);
const { error } = storeToRefs(errorStore);
const { isVerticalNavExpanded: isExpanded } = storeToRefs(layoutStore);

const isHovered = useElementHover(refNav)

provide(injectionKeyIsVerticalNavHovered, isHovered)

const route = useRoute()
const router = useRouter();

watch(() => route.name, () => {
  resizeMenu(false);
})

const resizeMenu = (expand: boolean) => {
  isExpanded.value = expand;

  const sidebar = document.querySelector(".sidebar")
  const layoutWrapper = document.querySelector(".layout-wrapper")

  if(!layoutWrapper || !sidebar) {
    return;
  }

  if (expand) {
    layoutWrapper.classList.remove('layout-vertical-nav-collapsed')
    sidebar.classList.add("open");

    return;
  }
 
  layoutWrapper.classList.add('layout-vertical-nav-collapsed')
  sidebar.classList.remove("open");
}

const handleMenu = () => {
  resizeMenu(!isExpanded.value);
}

const logout = async () => {
  router.push('/logout');
}

const orgStore = useOrganizationStore()
const workspaceStore = useWorkspaceStore()
const agentStore = useAgentStore()
const { currentAgent } = storeToRefs(agentStore)

onMounted(async () => {
  await Promise.all([
    await orgStore.getCurrentOrganization(),
    await workspaceStore.getCurrentWorkspace(),
  ])
  await agentStore.getCurrentAgent()
})

const VIEWER = 'viewer'

const isStoriesEnabled = computed(():boolean => {
  if(!features.value) return false;

  return features.value['convert-stories']?.value !== false;
});
</script>

<template>
  <div
    v-if="!error"
    class="sidebar"
    style="z-index: 999; 
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  "
  >
    <div class="logo-details">
      <VBtn
        size="50"
        color="rgb(0,0,0,0)"
        :ripple="false"
        @click="handleMenu"
      >
        <KonvoBrandmark
          v-if="!isExpanded"
          class="mt-3"
        />
      </VBtn>
      <VBtn
        v-show="isExpanded"
        size="50"
        color="rgb(0,0,0,0)"
        variant="text"
        @click="handleMenu"
      >
        <div
          class="logo_name"
        >
          <KonvoLogo />
        </div>
      </VBtn>
    </div>
    <ul class="nav-list">
      <VerticalNavItem
        v-if="orgStore.currentOrganization"
        name="Dashboard"
        icon="mingcute:dashboard-3-line"
        path="/"
        exact
      />
      <VerticalNavItem
        v-if="orgStore.currentOrganization"
        name="Inbox"
        icon="mingcute:chat-1-line"
        path="/inbox"
      />
      <VerticalNavItem
        v-if="orgStore.currentOrganization && currentAgent?.role !== VIEWER"
        name="Audience"
        icon="mingcute:contacts-line"
        path="/audience"
      />
      <VerticalNavItem
        v-if="orgStore.currentOrganization && currentAgent?.role !== VIEWER"
        name="Broadcasts"
        icon="mingcute:announcement-line"
        path="/broadcasts"
      />
      <VDivider
        v-if="currentAgent?.role !== VIEWER"
        color="white" 
      />
      <VerticalNavItem
        v-if="orgStore.currentOrganization && currentAgent?.role !== VIEWER"
        name="Konvo AI"
        icon="mingcute:crystal-ball-line"
        path="/ai"
      />
      <VerticalNavItem
        v-if="isStoriesEnabled && orgStore.currentOrganization && currentAgent?.role !== VIEWER"
        :beta="true"
        name="Stories"
        icon="mingcute:book-5-line"
        path="/stories"
      />
      <VerticalNavItem
        v-if="orgStore.currentOrganization && currentAgent?.role !== VIEWER"
        name="Flows"
        icon="mingcute:department-line"
        path="/flows"
      />
      <VerticalNavItem
        v-if="!orgStore.currentOrganization && currentAgent?.role !== VIEWER"
        name="Billing"
        icon="mingcute:bill-line"
        path="/billing"
      />
      <VerticalNavItem
        v-if="orgStore.currentOrganization"
        name="Settings"
        icon="mingcute:settings-3-line"
        path="/settings"
        :bottom="true"
      />
      <li
        class="profile"
        style="border-bottom-right-radius: 20px;"
      >
        <div class="profile-details">
          <VAvatar
            v-if="!isExpanded"
            style="background-color: transparent;"
            :image="orgStore.currentOrganization?.logoUrl"
            size="40"
          />
          <VBtn
            v-else
            size="45"
            rounded
            @click="logout()"
          >
            <VIcon
              icon="mingcute:exit-line"
              size="25"
            />
          </VBtn>
          <div
            v-if="isExpanded"
            class="profile-details-content"
          >
            <div
              class="navbar-bottom-title"
              :title="orgStore.currentOrganization?.name"
            >
              {{ orgStore.currentOrganization?.name }}
            </div>
            <div
              class="navbar-bottom-subtitle"
              style="font-size: 15px;"
              :title="workspaceStore.currentWorkspace?.name"
            >
              {{ workspaceStore.currentWorkspace?.name }}
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
@use "@configured-variables" as variables;
@use "@layouts/styles/mixins";

.profile-details-content {
  margin-left: 15px;
  overflow: hidden;
}

.navbar-bottom-title {
  color: #FFF;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.navbar-bottom-subtitle {
  color: #FFF;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-button-item {
  border-radius: 15px;
}

.nav-button-tooltip {
  margin: 0px;
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

// 👉 Vertical Nav
.layout-vertical-nav {
  position: fixed;
  z-index: variables.$layout-vertical-nav-z-index;
  display: flex;
  flex-direction: column;
  block-size: 100%;
  inline-size: variables.$layout-vertical-nav-width;
  inset-block-start: 0;
  inset-inline-start: 0;
  transition: transform 0.25s ease-in-out, inline-size 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  will-change: transform, inline-size;

  .nav-header {
    display: flex;
    align-items: center;

    .header-action {
      cursor: pointer;
    }
  }

  .app-title-wrapper {
    margin-inline-end: auto;
  }

  .divider-wrapper {
    margin-top: 1000px;
  }

  .nav-items {
    block-size: 100%;

    // ℹ️ We no loner needs this overflow styles as perfect scrollbar applies it
    // overflow-x: hidden;

    // // ℹ️ We used `overflow-y` instead of `overflow` to mitigate overflow x. Revert back if any issue found.
    // overflow-y: auto;
  }

  .nav-item-title {
    overflow: hidden;
    margin-inline-end: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  // 👉 Collapsed
  .layout-vertical-nav-collapsed & {
    &:not(.hovered) {
      inline-size: variables.$layout-vertical-nav-collapsed-width;
    }
  }

  // 👉 Overlay nav
  &.overlay-nav {
    &:not(.visible) {
      transform: translateX(-#{variables.$layout-vertical-nav-width});

      @include mixins.rtl {
        transform: translateX(variables.$layout-vertical-nav-width);
      }
    }
  }
}


/* Google Font Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans" , sans-serif;
  //font-family: "Poppins" , sans-serif;
}

.sidebar{
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 50px;
  background: #281222;
  padding: 5px 7px;
  z-index: 99;
  transition: all 0.5s ease;
  overflow: hidden;
}
.sidebar.open{
  width: 200px;
}
.sidebar .logo-details{
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .icon{
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar .logo-details .logo_name{
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
  margin-top: 10px;
  margin-left: -10px;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name{
  opacity: 1;
}
.sidebar .logo-details #btn{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn{
  text-align: right;
}
.sidebar i{
  color: #fff;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list{
  margin-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.sidebar li{
  position: relative;
  margin: 6px 0;
  list-style: none;
}
.sidebar li .tooltip{
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.sidebar li:hover .tooltip{
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar.open li .tooltip{
  display: none;
}
.sidebar input{
  font-size: 15px;
  color: #FFF;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1d1b31;
}
.sidebar.open input{
  padding: 0 20px 0 50px;
  width: 100%;
}
.sidebar .bx-search{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #1d1b31;
  color: #FFF;
}
.sidebar.open .bx-search:hover{
  background: #1d1b31;
  color: #FFF;
}
.sidebar .bx-search:hover{
  background: #FFF;
  color: #11101d;
}
.sidebar li a{
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #11101D;
}

.sidebar li a:hover{
  background: #FFF;
}

.sidebar li a .links_name{
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.sidebar.open li a .links_name{
  opacity: 1;
  pointer-events: auto;
}
.sidebar li a:not(.router-link-active):hover .links_name,
.sidebar li a:not(.router-link-active):hover i{
  transition: all 0.5s ease;
  color: #11101D;
}
.sidebar li i{
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.sidebar li.profile{
  position: fixed;
  height: 65px;
  width: 50px;
  left: 0;
  bottom: -8px;
  padding: 10px;
  transition: all 0.5s ease;
  overflow: hidden;
}
.sidebar.open li.profile{
  width: 200px;
}
.sidebar li .profile-details{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: -5px;
}
.sidebar li img{
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 6px;
}
.sidebar li.profile .name,
.sidebar li.profile .job{
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}
.sidebar li.profile .job{
  font-size: 12px;
}
.sidebar .profile #log_out{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #1d1b31;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}
.sidebar.open .profile #log_out{
  width: 50px;
  background: none;
}
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px
}
@media (max-width: 420px) {
  .sidebar li .tooltip{
    display: none;
  }
}
</style>
