<script lang="ts" setup>
import { useDashboardStore } from '@/stores/useDashboardStore';
import { AppChartGrouping } from "@/types/app-component.types";

interface Props {
  fromDate: Date;
  toDate: Date;
}

interface Emits {
  (e: 'update', event: { from: Date, to: Date }): void;
}

defineProps<Props>();
defineEmits<Emits>();

const dashboardStore = useDashboardStore();
const { chartGrouping } = storeToRefs(dashboardStore);

const chartGroupingOptions = [
  AppChartGrouping.Daily,
  AppChartGrouping.Weekly,
  AppChartGrouping.Monthly,
];
</script>

<template>
  <div class="d-flex justify-space-between align-center">
    <AppText
      text="Performance overview"
      text-style="frame-title"
    />
    <div class="d-flex ga-2">
      <AppDateRangePicker
        :from="fromDate"
        :to="toDate"
        @update="$emit('update', $event)"
      />
      <AppMenuPicker
        :options="chartGroupingOptions"
        :value="chartGrouping"
        @update:value="chartGrouping = $event"
      />
    </div>
  </div>
</template>
