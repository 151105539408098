<script lang="ts" setup>
import { useDashboardStore } from '@/stores/useDashboardStore';

interface Props {
  currency: string;
}

defineProps<Props>();

const dashboardStore = useDashboardStore();
const { chartLabels, revenueAttributionChartData } = storeToRefs(dashboardStore);

const series = computed(() => {
  return [{
    name: 'Total revenue',
    data: revenueAttributionChartData.value,
  }]
})

const chartOptions = {
  responsive: true,
};
</script>

<template>
  <AppPanel class="py-6">
    <div class="px-4">
      <AppText
        is="h3"
        text-style="frame-subtitle"
        text="Total Revenue Attributed"
        class="mb-4"
      />
      <AppChartLine
        :labels="chartLabels"
        :series="series"
        :height="200"
        :chart-options="chartOptions"
        :currency="currency"
      />
    </div>
  </AppPanel>
</template>
