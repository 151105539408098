import { metricsService } from "@/services/metricsService";
import { useWorkspaceStore } from "./useWorkspaceStore"
import { revenueAttributionService } from "@/services/revenueAttributionService";
import { ordersService } from "@/services/ordersService";
import { useSnackbarStore } from "@/stores/useSnackbarStore";
import { AppChartGrouping } from "@/types/app-component.types";
import { generateDateLabels } from "@/utils/generateDateLabels";
import { chartGroupingDateFormatter } from "@/utils/chartHelpers";
import { RevenueAttributionDto } from "@/types/revenue-attribution.types";
import { AiSession } from "@/types/ai.types";

interface DashboardStoreState {
  conversationsCount: number,
  conversationsCountPrevious: number,
  resolutionsCount: number,
  resolutionsCountPrevious: number,
  newSubscribersCount: number,
  newSubscribersCountPrevious: number,
  attributedRevenue: number,
  attributedRevenuePrevious: number,
  totalRevenue: number,
  totalCost: number,
  isFetchingData: boolean,
  isFetchingPreviousData: boolean,
  fromDate?: Date,
  toDate?: Date,
  chartGrouping: AppChartGrouping,
  revenueAttributionItems: RevenueAttributionDto[],
  aiSessions: AiSession[],
}

export const useDashboardStore = defineStore('dashboard', {
  state: (): DashboardStoreState => ({
    conversationsCount: 0,
    conversationsCountPrevious: 0,
    resolutionsCount: 0,
    resolutionsCountPrevious: 0,
    newSubscribersCount: 0,
    newSubscribersCountPrevious: 0,
    attributedRevenue: 0,
    attributedRevenuePrevious: 0,
    totalRevenue: 0,
    totalCost: 0,
    isFetchingData: false,
    isFetchingPreviousData: false,
    fromDate: undefined,
    toDate: undefined,
    chartGrouping: AppChartGrouping.Daily,
    revenueAttributionItems: [],
    aiSessions: [],
  }),
  getters: {
    chartLabels(state): string[] {
      if (!state.fromDate || !state.toDate) return [];

      return generateDateLabels(state.fromDate, state.toDate, chartGroupingDateFormatter[state.chartGrouping]);
    },
    revenueAttributionChartData(state): string[] {
      const totals: Record<string, number> = {};
      const labelGrouping = chartGroupingDateFormatter[state.chartGrouping];

      state.revenueAttributionItems.forEach(item => {
        const itemDateLabel = labelGrouping(new Date(item.createdAt));
        const previousTotal = totals[itemDateLabel] ?? 0;

        totals[itemDateLabel] = previousTotal + (item.orderTotal / 100);
      });

      return this.chartLabels.map((label) => {
        return (totals[label] ?? 0).toFixed(2);
      });
    },
    aiSessionsChartData(state): number[] {
      const counts: Record<string, number> = {};
      const labelGrouping = chartGroupingDateFormatter[state.chartGrouping];

      state.aiSessions.forEach(item => {
        const itemDateLabel = labelGrouping(new Date(item.session.created_at));
        const previousCount = counts[itemDateLabel] ?? 0;

        counts[itemDateLabel] = previousCount + 1;
      });

      return this.chartLabels.map((label) => {
        return counts[label] ?? 0;
      });
    }
  },
  actions: {
    async fetchData(fromDate: Date, toDate: Date, previous = false) {
      const snackbarStore = useSnackbarStore();
      const workspaceStore = useWorkspaceStore();

      const workspaceId = await workspaceStore.getWorkspaceId();

      if (!previous) {
        this.$patch({
          isFetchingData: true,
        });  
      }

      const [
        conversations,
        resolutions,
        subscribers,
        revenueAttribution,
        orders,
        revenueAttributionGraph,
        aiSessionsResponse,
      ] = await Promise.all([
        await metricsService.fetchAiSessionsOverview(workspaceId, fromDate, toDate).catch(snackbarStore.handleError),
        await metricsService.fetchAiResolutions(workspaceId, fromDate, toDate).catch(snackbarStore.handleError),
        await metricsService.fetchNewSubscribersCount(workspaceId, fromDate, toDate).catch(snackbarStore.handleError),
        await revenueAttributionService.fetchSum(workspaceId, fromDate, toDate).catch(snackbarStore.handleError),
        !previous && await ordersService.fetchSum(workspaceId, fromDate, toDate).catch(snackbarStore.handleError),
        !previous && await revenueAttributionService.fetchAll(workspaceId, fromDate, toDate).catch(snackbarStore.handleError),
        !previous && await metricsService.fetchAiSessions(workspaceId, fromDate, toDate).catch(snackbarStore.handleError),
      ]);

      const conversationsCount = conversations?.data.data.reduce((agg, item) => item.count + agg, 0);
      const resolutionsCount = resolutions?.data.data.breakdown.resolved;
      const newSubscribersCount = subscribers?.data.data;
      const attributedRevenue = revenueAttribution?.data.data.sum;

      if (previous) {
        this.$patch({
          conversationsCountPrevious: conversationsCount,
          resolutionsCountPrevious: resolutionsCount,
          newSubscribersCountPrevious: newSubscribersCount,
          attributedRevenuePrevious: attributedRevenue,
          isFetchingPreviousData: false,
        });

        return;
      }

      const totalRevenue = orders ? orders.data.sum : 0;
      const totalCost = revenueAttribution?.data.data.cost;
      const revenueAttributionItems = revenueAttributionGraph ? revenueAttributionGraph.data.data : [];
      const aiSessions = aiSessionsResponse ? aiSessionsResponse.data.data : [];

      this.$patch({
        conversationsCount,
        resolutionsCount,
        newSubscribersCount,
        attributedRevenue,
        totalRevenue,
        totalCost,
        isFetchingData: false,
        isFetchingPreviousData: true,
        fromDate,
        toDate,
        revenueAttributionItems,
        aiSessions
      });

      this.fetchDataForPrevious(fromDate, toDate);
    },

    async fetchDataForPrevious(fromDate: Date, toDate: Date) {
      const duration = toDate.getTime() - fromDate.getTime();
      const previousTo = new Date(fromDate);

      previousTo.setDate(previousTo.getDate() - 1);

      const previousFrom = new Date(previousTo.getTime() - duration);

      return this.fetchData(previousFrom, previousTo, true);
    }
  }
})
