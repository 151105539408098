import { defineStore } from 'pinia'
import axiosApi from '@/plugins/axios/axiosApi'
import { FeedMetadataDto } from "@/types/inbox.types";
import { useInboxCurrentFeedStore } from './useInboxCurrentFeedStore';
import { keywordSearch } from '@/utils/keywordSearch';
import { mapArray } from '@/utils/mapArray';

export type PendingAgentAction = 'Reactivate' | 'Deactivate' | 'Delete' | 'Uninvite' | 'Re-invite'

export interface AgentDto {
  id: string
  firstName: string
  lastName: string
  email: string
  currentWorkspaceId: string | undefined
  role: string
  status: string
  createdAt: Date
  onHoliday: boolean
  lastLogin?: Date
  avatar?: string
  assignedFeeds: string[]
  mentionedFeeds: string[]
  phoneNumber?: string
}

export type EditableAgentFields = Pick<AgentDto, 'firstName' | 'lastName' | 'email' | 'avatar' | 'role'>
export type EditAgentPayload = Partial<EditableAgentFields>

export interface AgentTeamDto {
    id: string
    name: string
    avatar: { emoji: string, color?: string }
    agents: AgentDto[]
}

interface State {
    agents: AgentDto[]
    teams: AgentTeamDto[]
    currentAgent: AgentDto | undefined
}

export const useAgentStore = defineStore('agentStore', {
    state: (): State => ({
        agents: [],
        teams: [],
        currentAgent: undefined
    }),
    getters: {
        allAssignedFeedIds(state): string[] {
            return state.agents.flatMap(item => item.assignedFeeds);
        },
        agentsById(state) {
            return mapArray(state.agents, 'id');
        }
    },
    actions: {
        async checkReload(): Promise<void> {
            if(this.agents.length === 0) {
                await this.fetchAgents()
                await this.fetchTeams()
            }
        },
        findByIds(ids: string[]): AgentDto[] {
            return this.agents.filter(agent => ids.includes(agent.id))
        },
        excludeByIds(ids: string[]): AgentDto[] {
            return this.agents.filter(agent => !ids.includes(agent.id))
        },
        findTeamByIds(ids: string[]): AgentTeamDto[] {
            return this.teams.filter(team => ids.includes(team.id))
        },
        excludeTeamByIds(ids: string[]): AgentTeamDto[] {
            return this.teams.filter(team => !ids.includes(team.id))
        },
        async getCurrentAgent(): Promise<void> {
            if(!this.currentAgent) {
                await this.fetchAgents()
                const { data } = await axiosApi.get(`session/agent`, { withCredentials: true })  
                const { agentId } = data as { agentId: string }
                this.currentAgent = this.agents.find(agent => agent.id === agentId)
            }

            await this.fetchFeedMetadataForAgent()
        },
        async fetchFeedMetadataForAgent(): Promise<void> { 
            const { data } = await axiosApi.get('agents/current/feed-metadata', { withCredentials: true })  
            const { agent } = data

            this.currentAgent = agent
        },
        async fetchAgents(): Promise<AgentDto[]> { 
            const { data } = await axiosApi.get('agents', { withCredentials: true })  
            const { agents } = data

            this.agents = agents.filter((agent: AgentDto) => agent.status !== 'deleted')
            return agents
        },

        async searchAgents(query: string): Promise<AgentDto[]> {
            if (!this.agents.length) {
                await this.fetchAgents();
            }

            return this.agents.filter(item => {
                return keywordSearch(query, `${item.firstName || ''} ${item.lastName || ''}`.trim())
            });
        },

        async assignAgent(agentId: string, feedId: string): Promise<FeedMetadataDto> { 
            const chatStore = useInboxCurrentFeedStore()
            const { data } = await axiosApi.post(`agents/${agentId}/assign/${feedId}`, {}, { withCredentials: true })  
            const { feedMetadata } = data as { feedMetadata: FeedMetadataDto }

            chatStore.feedMetadata = feedMetadata

            return feedMetadata
        },
        async unassignAgent(agentId: string, feedId: string): Promise<FeedMetadataDto> { 
            const chatStore = useInboxCurrentFeedStore()
            const { data } = await axiosApi.delete(`agents/${agentId}/unassign/${feedId}`, { withCredentials: true })  
            const { feedMetadata } = data as { feedMetadata: FeedMetadataDto }

            chatStore.feedMetadata = feedMetadata

            return feedMetadata
        },
        async mentionAgent(agentId: string, feedId: string): Promise<FeedMetadataDto> { 
            const chatStore = useInboxCurrentFeedStore()
            const { data } = await axiosApi.post(`agents/${agentId}/mention/${feedId}`, {}, { withCredentials: true })  
            const { feedMetadata } = data as { feedMetadata: FeedMetadataDto }

            chatStore.feedMetadata = feedMetadata

            if(agentId === this.currentAgent?.id) {
                await this.fetchFeedMetadataForAgent()
            }

            return feedMetadata
        },
        async assignTeam(teamId: string, feedId: string): Promise<FeedMetadataDto> { 
            const chatStore = useInboxCurrentFeedStore()
            const { data } = await axiosApi.post(`agents/teams/${teamId}/assign/${feedId}`, {}, { withCredentials: true })  
            const { feedMetadata } = data as { feedMetadata: FeedMetadataDto }

            chatStore.feedMetadata = feedMetadata

            return feedMetadata
        },
        async fetchTeams(): Promise<AgentTeamDto[]> { 
            const { data } = await axiosApi.get('agents/teams', { withCredentials: true })  
            const { teams } = data

            this.teams = teams

            return teams
        },
        async unassignTeam(teamId: string, feedId: string): Promise<FeedMetadataDto> { 
            const chatStore = useInboxCurrentFeedStore()
            const { data } = await axiosApi.delete(`agents/teams/${teamId}/unassign/${feedId}`, { withCredentials: true })  
            const { feedMetadata } = data as { feedMetadata: FeedMetadataDto }

            chatStore.feedMetadata = feedMetadata

            return feedMetadata
        },
        async mentionTeam(teamId: string, feedId: string): Promise<FeedMetadataDto> { 
            const chatStore = useInboxCurrentFeedStore()
            const { data } = await axiosApi.post(`agents/teams/${teamId}/mention/${feedId}`, {}, { withCredentials: true })  
            const { feedMetadata } = data as { feedMetadata: FeedMetadataDto }

            chatStore.feedMetadata = feedMetadata

            return feedMetadata
        },
    },
})
