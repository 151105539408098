import { personasService } from "@/services/personas/personasService";
import { Persona } from "@/types/persona.types";
import { useWorkspaceStore } from "./useWorkspaceStore";
import { useSnackbarStore } from './useSnackbarStore'
import { AssetUploadLocation, useAssetStore } from "./useAssetStore";

interface PersonasStore {
  persona: Persona | null;
  isLoading: boolean;
  isInProgress: boolean,
}

export const usePersonasStore = defineStore('personas', {
  state: (): PersonasStore => ({
    persona: null,
    isLoading: false,
    isInProgress: false,
  }),
  actions: {
    async loadPersona() {
      const workspaceStore = useWorkspaceStore();
      const snackbarStore = useSnackbarStore();

      this.isLoading = true;

      const workspaceId = await workspaceStore.getWorkspaceId();
      const allPersonas = await personasService.fetchAll(workspaceId).catch(snackbarStore.handleError);

      if (!allPersonas) {
        this.isLoading = false;

        return;
      }

      if (allPersonas[0]) {
        this.$patch({
          persona: allPersonas[0],
          isLoading: false,
        });
        return;
      }

      await this.createNewPersona();
    },

    async createNewPersona() {
      const workspaceStore = useWorkspaceStore();
      const snackbarStore = useSnackbarStore();

      const workspaceId = await workspaceStore.getWorkspaceId();
      const persona = await personasService.create(workspaceId, {
        name: '',
        avatar: '🙂',
        personality: '',
        writingStyle: '',
        tones: [],
        emojis: [],
        skills: [],
        enabled: true,
      }).catch(snackbarStore.handleError);

      if (persona) {
        this.$patch({
          persona: persona,
          isLoading: false,
        });

        return;
      }

      this.isLoading = false;
    },

    async patchPersona() {
      const workspaceStore = useWorkspaceStore();
      const snackbarStore = useSnackbarStore();

      if (!this.persona) throw Error('No persona to patch');

      this.isLoading = true;

      const workspaceId = await workspaceStore.getWorkspaceId();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, skills, publishedAt, updatedAt, ...data } = this.persona;
      const persona = await personasService.patch(workspaceId, id, data).catch(snackbarStore.handleError);

      if (persona) {
        this.$patch({
          persona: persona,
          isLoading: false,
        });

        return;
      }

      this.isLoading = false;
    },

    async publishPersona() {
      const workspaceStore = useWorkspaceStore();
      const snackbarStore = useSnackbarStore();

      if (!this.persona) throw Error('No persona to publish');

      this.isLoading = true;

      const workspaceId = await workspaceStore.getWorkspaceId();
      const persona = await personasService.publish(workspaceId, this.persona.id).catch(snackbarStore.handleError);

      if (persona) {
        this.$patch({
          persona: persona,
          isLoading: false,
        });

        return;
      }

      this.isLoading = false;
    },
    async enablePersona() {
      const workspaceStore = useWorkspaceStore();
      const snackbarStore = useSnackbarStore();

      if (!this.persona) throw Error('No persona to enable');

      this.isLoading = true;

      const workspaceId = await workspaceStore.getWorkspaceId();
      const persona = await personasService.enable(workspaceId, this.persona.id).catch(snackbarStore.handleError);

      if (persona) {
        this.$patch({
          persona: persona,
          isLoading: false,
        });

        return;
      }

      this.isLoading = false;
    },
    async disablePersona() {
      const workspaceStore = useWorkspaceStore();
      const snackbarStore = useSnackbarStore();

      if (!this.persona) throw Error('No persona to disable');

      this.isLoading = true;

      const workspaceId = await workspaceStore.getWorkspaceId();
      const persona = await personasService.disable(workspaceId, this.persona.id).catch(snackbarStore.handleError);

      if (persona) {
        this.$patch({
          persona: persona,
          isLoading: false,
        });

        return;
      }

      this.isLoading = false;
    },
    async setAvatar(file: File): Promise<void> {
      const assetStore = useAssetStore();

      if (!this.persona) return;

      const url = await assetStore.upload(AssetUploadLocation.PERSONAS, file);

      if (!url) return;

      this.persona.avatar = url;

      await this.patchPersona();
    },
  },
});
