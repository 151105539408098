<script lang="ts" setup>
import { useErrorStore } from '@/stores/useErrorStore';

const errorStore = useErrorStore();
const { error } = storeToRefs(errorStore);
</script>

<template>
  <ErrorView
    v-if="error"
    :error="error"
  />
  <slot v-else />
</template>
