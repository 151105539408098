<script lang="ts" setup>
import { useDashboardStore } from '@/stores/useDashboardStore';
import { useMinLoading } from '@/utils/useMinLoading';

interface Props {
  label: string;
  increase?: number;
}

defineProps<Props>();

const dashboardStore = useDashboardStore();
const { isFetchingData, isFetchingPreviousData } = storeToRefs(dashboardStore);

const {
  setLoading: setIsLoadingData,
  getLoading: getIsLoadingData,
} = useMinLoading();

const {
  setLoading: setIsLoadingPreviousData,
  getLoading: getIsLoadingPreviousData,
} = useMinLoading();

watchEffect(() => {
  setIsLoadingData.value = isFetchingData.value;
});

watchEffect(() => {
  setIsLoadingPreviousData.value = isFetchingPreviousData.value;
});
</script>

<template>
  <AppOverviewField
    :label="label"
    :is-loading="getIsLoadingData"
    :is-loading-previous-data="getIsLoadingPreviousData"
    :increase="increase"
  >
    <slot />
  </AppOverviewField>
</template>

