export type AppChipStatus = 'info' | 'success' | 'warning' | 'danger' | 'unknown' | 'general';

export type AppNestedSelectOption = {
  title: string,
  value: string | undefined,
  items?: {
    title: string,
    value: string,
  }[],
};

export interface AppDropdownListItem {
  title: string,
  type: 'subheader' | 'item'
  icon?: string;
  color?: string;
  value: string | undefined
}

export interface AppChartLineData {
  name: string
  data: any[]
}

export enum AppChartGrouping {
  Daily="Daily",
  Weekly="Weekly",
  Monthly="Monthly",
}
